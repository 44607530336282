import React , {useEffect, useState} from 'react'
import { getSettingsData } from '../Hooks/UseBackend';
import cicon from '../Assets/images/grunimg/c1.png';
import tele from '../Assets/images/grunimg/tele1.png';
import whatsappicon from '../Assets/images/grunimg/what1.png';

const Footer = () => {
    const [settingdata,setsettingdata] = useState([])
    useEffect(() =>{
        getData()
    })

    const getData =async() =>{
        var settingdata = await getSettingsData();
  setsettingdata(settingdata);
    }
    return(
        <div className='footer'>
            <div className='container container-theme'>
                <div className='row newletterrow'>
                    
                    {/* <div className='col-sm-4'>
                        <ul className='text-sm-start ps-0'>
                           <li>
                                <a target="blank" href={settingdata && settingdata[0]?.Description}>
                                    <span className='fa fa-facebook-square'></span>
                                </a>
                            </li>
                            <li>
                                <a target="blank"href={settingdata && settingdata[1]?.Description}>
                                    <span className='fa fa-twitter-square'></span>
                                </a>
                            </li>
                            <li>
                                <a target="blank"href={settingdata && settingdata[2]?.Description}>
                                    <span className='fa fa-instagram'></span>
                                </a>
                            </li>
                        </ul>
                    </div> */}
                    <div className='col-sm-8 '>
                        <p className='text-left textclr'>COPYRIGHT @ 2023 .ALL RIGHTS RESERVED</p>
                    </div>
                    <div className='col-sm-4'>
                        <a href='/https://calendly.com/d/y6r-qmv-3s6/30min?month=2023-07' target='_blank'><img className='cicon' src={cicon} /></a>
                        <a href='/https://t.me/gruncoinofficial' target='_blank'><img className='teleicon' src={tele} /></a>
                        <a href='https://wa.me/qr/CM3R2GL33U2OH1' target='_blank'><img className='whatsappicon' src={whatsappicon} /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer