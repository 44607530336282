import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import logo from "../Assets/images/logo.png"
import { NavLink } from 'react-router-dom';
import Connect from './Modal/ConnectModal';
import { getAccount } from '../Hooks/useAccounts';
import { UseProvider } from '../Hooks/UseWeb3';
import { style, iconTheme, position } from '../Hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';
import { getSettingsData } from '../Hooks/UseBackend';

const Header = () => {
    const [connect, setConnect] = useState(false)
    const [accountInfo, setAccountInfo] = useState("");
    const [settingdata, setsettingdata] = useState([]);

    const [theme, settheme] = useState(true);

    useEffect(() => {
        setAccountInfo(getAccount());
        getData()
    }, []);

    const themechange = () => {
        if (document.getElementById("root").classList.contains("light_theme")) {
            document.getElementById("root").classList.add("dark_theme")
            document.getElementById("root").classList.remove("light_theme");
            // this.setState({theme:false})
            // this.state.theme === false
            settheme(true)

        } else {
            document.getElementById("root").classList.remove("dark_theme")
            document.getElementById("root").classList.add("light_theme")
            // this.state.theme === true
            // this.setState({theme:true})
            settheme(false)
        }
    }

    const logOut = async () => {
        setAccountInfo("");
        localStorage.removeItem("accountInfo")
        if (localStorage.getItem("walletconnect") != null) {
            const provider = await UseProvider();
            await provider.disconnect()
        }


        toast.success("Wallet Disconnected successfully", {
            position: position.position,
            style: style,
            iconTheme: iconTheme,
        })
        setTimeout(() => {

            window.location.reload()
        }, 1000);
    }



    const getData = async () => {
        var settingdata = await getSettingsData();
        setsettingdata(settingdata);
    }

    return (
        <div className='header'>
            {connect && <Connect onDismiss={() => setConnect(false)} />}
            <Navbar bg="dark" expand="lg" variant="dark">
                <div className='container container-theme'>
                    <Navbar.Brand href="/">
                        <img src={logo} className="logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <NavLink className="nav-link" to="/">Home</NavLink>
                            <NavLink className="nav-link" to="/launchpad">Launchpad</NavLink>
                            <NavLink className="nav-link" to="/staking">Locked Staking</NavLink>
                            <NavLink className="nav-link" to="/news">News</NavLink>
                            {/* <a target="blank"className="nav-link" href={settingdata && settingdata[3]?.Description} >News</a> */}

                            <NavLink className="nav-link" to="/map">Map</NavLink>
                            {(accountInfo == "" || accountInfo == null) ?
                                <button className='btn btn getstarted ms-lg-3' onClick={() => setConnect(true)}>Connect</button> :
                                <button className='btn btn getstarted ms-lg-3' onClick={() => logOut()}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>}

                            <button className='btn moonlight' onClick={() => themechange()}><span className={theme ? 'fa fa-moon' : 'fa fa-sun'} ></span></button>
                           
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </div>
    )
}

export default Header;