import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import Header from './Header'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Img1 from "../Assets/images/grunimg/partnerlogo.png"
import Img2 from "../Assets/images/grunimg/partnerlogo1.png"
import Img3 from "../Assets/images/grunimg/partnerlogo.png"
import Img4 from "../Assets/images/grunimg/partnerlogo1.png"
import Img5 from "../Assets/images/grunimg/partnerlogo.png"
import Img6 from "../Assets/images/grunimg/partnerlogo1.png"
import Img7 from "../Assets/images/grunimg/partnerlogo.png"
import Img8 from "../Assets/images/grunimg/partnerlogo1.png"
import Img9 from "../Assets/images/img9.svg"
import Img10 from "../Assets/images/img10.svg"
import Img11 from "../Assets/images/img11.svg"
import Img12 from "../Assets/images/img12.svg"
import Img13 from "../Assets/images/img13.svg"
import Img14 from "../Assets/images/img14.svg"
import Img15 from "../Assets/images/img15.svg"
import Img16 from "../Assets/images/img16.svg"
import Img17 from "../Assets/images/img17.svg"
import Img18 from "../Assets/images/img18.svg"
import Img19 from "../Assets/images/img19.svg"
import Img20 from "../Assets/images/img20.svg"
import Img21 from "../Assets/images/img21.svg"
import banner from "../Assets/images/banner.png"
import whylabel from "../Assets/images/whylabel.png"
import files from "../Assets/images/files.png"
import trading from "../Assets/images/trading.png"
import { getAboutData, getFeatureData, getFeaturedesData, getLaunchpadData, getRoadmapdesData, getSettingsData } from '../Hooks/UseBackend';
import { backurl } from '../Config/env';
import { UsegetsingleSaleInfo } from '../Hooks/useContract';

import grunimg from '../Assets/images/grunimg/grunwelcomeimg.png';
import grundo from '../Assets/images/grunimg/grundo.png';
import rightarrow from '../Assets/images/grunimg/rightarrow.png';
import blackarrow from '../Assets/images/grunimg/blackarrow.png';
import feature from '../Assets/images/grunimg/feature.png';
import sideimg from '../Assets/images/grunimg/sideimg.png';
import nutss from '../Assets/images/grunimg/nuts.png';
import nutssmall from '../Assets/images/grunimg/nutssmall.png';
import no1 from '../Assets/images/grunimg/no1.png';
import CountUp from 'react-countup';
import ReactReadMoreReadLess from "react-read-more-read-less";
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Land = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  const [aboutdata, setaboutdata] = useState([])
  const [featuredata, setfeaturedata] = useState([])
  const [featuredesdata, setfeaturedesdata] = useState([])

  const [roadmapdata, setroadmapdata] = useState([])
  const [totalLaunchPadlength, settotalLaunchPadlength] = useState([])
  const [singledata1, setsingledata1] = useState({})
  const [singledata2, setsingledata2] = useState({})
  const [singledata3, setsingledata3] = useState({});
  const myLongText =
    "Gruncoin is a crypto wallet app that provides a range of features for its users.It is primarily designed as a gold investment app, allowing users to invest in gold using Gruncoin or Usdt quickly and securely. With real-time pricing information and instant trades.In addition. Gruncoin also offers a range of other services. Users can use the app to purchase a variety of different products at discount. Including electronics, clothing, travel, hotel and more. Using Gruncoin with full money back garrantee. This makes it easy to use cryptocurrency for everyday purchases.Without the need to having to convert to fiat currencey.";

  const myLongText1 =
    "Gruncoin will at all time buy back Grun at the current price of gold. 1 Grun for 1 ounce gold. Or morefold. Gruncoin has an unlimited maximum value. And a guaranteed limited minimum value of 1 Grun for 1 ozt gold. Gruncoin is backed by gold.";

  const myLongText3 = "Gruncoin is a great choise for anyone looking to invest in gold or use cryptocurrency for everyday purchases with 50% discount.It gold investment along with its ability to purchase and book travel and more, make it a versatile app that can be used for a variety of purposes. With its user-friendly and secure platform.Gruncoin is a great choise for anyone looking to invest in gold or use cryptocurrency for everyday purchases Without the need to having to convert to fiat currencey."

  const responsive1 = {
    0: {
      items: 1,
    },
    424: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  }
  useEffect(() => {
    getData();

  }, [])


  const getData = async () => {

    var data = await getAboutData();
    setaboutdata(data)
    var feature = await getFeatureData();
    setfeaturedata(feature);
    var featruedes = await getFeaturedesData();
    setfeaturedesdata(featruedes)
    var roadmapdes = await getRoadmapdesData();
    setroadmapdata(roadmapdes)
    var data = await getLaunchpadData();

    settotalLaunchPadlength(data)
    var singledata = await UsegetsingleSaleInfo(data[0]?.presaleAddress);
    setsingledata1(singledata);
    var singledata2 = await UsegetsingleSaleInfo(data[1]?.presaleAddress);

    setsingledata2(singledata2);
    var singledata3 = await UsegetsingleSaleInfo(data[2]?.presaleAddress);
    setsingledata3(singledata3);


  }
  return (
    <div className='land'>
      <Header />
      <div className='container container-theme'>

        <section className='banner'>
          <img src={nutss} className='nutsss okeyy' alt='nutss' />
          <img src={nutss} className='nutsss1 okeyy' alt='nutss' />
          <img src={nutss} className='nutsss2 okeyy' alt='nutss' />
          <img src={nutss} className='nutsss3 okeyy' alt='nutss' />
          <img src={nutssmall} className='nutssmall okeyy' alt='nutss' />
          <div className='row align-items-center'>
            <div className='col-md-6' data-aos="fade-up" data-aos-duration="1500">
              <div className='ban-cont'>
                {/* <p className='themeclr'>{aboutdata && aboutdata[0]?.Title}</p> */}
                <h1 className='heading'>Welcome to <span className='titles'>Grun</span><span className='subtitles'>Coin</span></h1>
                <p>50% DISCOUNT ON TRAVEL AND HOTEL AND MORE....</p>
                <a target='blank' href="/"><button className='btn getstarted mt-4'>Get Started</button></a>
              </div>
            </div>
            <div className='col-md-6 d-none d-md-block' data-aos="fade-down" data-aos-duration="1500">
              <div className='bannerimg text-center'>
                <img src={grunimg} />
              </div>
            </div>
          </div>
        </section>

        <section className='memberlist'>
          <div className='row'>
            <div className='col-md-3 col-sm-6'>
              <h1>$<CountUp duration={5.75} end={42} />M</h1>
              <p>Contributions</p>
            </div>
            <div className='col-md-3 col-sm-6'>
              <h1>$<CountUp duration={5.75} end={56} />M</h1>
              <p>Distributions</p>
            </div>
            <div className='col-md-3 col-sm-6'>
              <h1><CountUp duration={5.75} end={90} />+</h1>
              <p>Startups</p>
            </div>
            <div className='col-md-3 col-sm-6'>
              <h1><CountUp duration={5.75} end={45000} /></h1>
              <p>KYC Verified Users</p>
            </div>
          </div>
        </section>

        <section className='banner whatdoes'>
          <div className='row align-items-center'>
            <div className='col-md-6 d-none d-md-block' data-aos="fade-down" data-aos-duration="1500">
              <div className='bannerimg text-center'>
                <img src={grundo} />
              </div>
            </div>
            <div className='col-md-6' data-aos="fade-up" data-aos-duration="1500">
              <div className='ban-cont'>
                <p className='themeclr'>GRUNCOIN</p>
                <h1 className='innerheading'>What does Gruncoin do?</h1>
                <p>Gruncoin offers 50% discount on goods and services of our partners.</p>
                <div className='d-flex'>
                  <a target='blank' href="/"><button className='btn getstarted mt-4'>Get Started</button></a>
                  <a className='ms-3' href="/launchpad"><button className='btn getstarted1 mt-4'>Buy Gruncoin now</button></a>
                </div>
                {/* <a target='blank' href="https://labelm.io/launchpad"><button className='btn getstarted'>Get Started</button></a> */}
              </div>
            </div>
          </div>
        </section>

        <section className='marketcapital bgtheme1 br-14 p-4 text-dark' data-aos="zoom-in" data-aos-duration="1500">
          {/* <h3 className='bold'>Market Cappitalization</h3>
          <p>$902,214,730</p> */}
          <h3 className='bold mb-3'>Information</h3>
          <div className='row'>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='infos'>
                <p className="mb-2">Sell 1 (90 days Locked  staking)</p>
                <p className="mb-2">Price:  1 USDT = {singledata1 && singledata1?.pricePerCrypto} GRUN</p>
                <p className="mb-2">Reward: 0.05%</p>
                <p className="mb-2">Sale : {singledata1 && singledata1.ispresaleopen ? "Ongoing " : "Sold Out"}</p>
                {/* <a target='blank' href="https://labelm.io/launchpad"> <button className='btn getstarted dark mt-3'>Get Started</button></a> */}

              </div>
            </div>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='infos'>
                <p className="mb-2">Sell 2 (180 days Locked  staking)</p>
                <p className="mb-2">Price: 1 USDT = {singledata2 && singledata2.pricePerCrypto} GRUN</p>
                <p className="mb-2">Reward: 2%</p>
                <p className="mb-2">Sale: {singledata2 && singledata2.ispresaleopen ? "Ongoing " : "Sold Out"}</p>
                {/* <a target='blank' href="https://labelm.io/launchpad"> <button className='btn getstarted dark mt-3'>Get Started</button></a> */}

              </div>
            </div>
            <div className='col-md-4 mb-4'>
              <div className='infos'>
                <p className="mb-2">Sell 3 (365 days Locked  staking)</p>
                <p className="mb-2">Price: 1 USDT = {singledata3 && singledata3?.pricePerCrypto} GRUN</p>
                <p className="mb-2">Reward: 15%</p>
                <p className="mb-2">Sale: {singledata3 && singledata3.ispresaleopen ? "Ongoing " : "Sold Out"}</p>


                {/* <a target='blank' href="https://labelm.io/launchpad"> <button className='btn getstarted dark mt-3'>Get Started</button></a> */}

              </div>
            </div>
          </div>
        </section>

        <section className='howdoes ban-cont'>
          <div>
            <h1 className='innerheading text-center'>How does it work?</h1>
            <p className='text-center'>Simple in 3 quick and easy steps.</p>
            <div className='row pt-4'>
              <div className='col-lg-4 col-md-6'>
                <div className='row pt-3 align-items-center'>
                  <div className='col-md-3 col-3'>
                    {/* <img className='w-100' src={no1}/> */}
                    <button className='btn stepbtn'>1</button>
                  </div>
                  <div className='col-md-7 col-9'>
                    <p className='stepno mb-2'>Step 1</p>
                    <p className='mb-0'>Download the app. Or stay on the website.</p>
                  </div>
                  <div className='col-md-2'>
                    <img className='w-100' src={rightarrow} />
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='row pt-3 align-items-center'>
                  <div className='col-md-3 col-3'>
                    {/* <img className='w-100' src={no1}/> */}
                    <button className='btn stepbtn1'>2</button>
                  </div>
                  <div className='col-md-7 col-9'>
                    <p className='stepno mb-2'>Step 2</p>
                    <p className='mb-0'>Buy Gruncoin with crypto USDT.</p>
                  </div>
                  <div className='col-md-2'>
                    <img className='w-100' src={rightarrow} />
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='row pt-3'>
                  <div className='col-md-3 col-3'>
                    {/* <img className='w-100' src={no1}/> */}
                    <button className='btn stepbtn1'>3</button>
                  </div>
                  <div className='col-md-9 col-9'>
                    <p className='stepno mb-2'>Step 3</p>
                    <p className='mb-0'>Click on the logo of our partners. And let us know what you like to order or reserve via mail or WhatsApp or telegram or call back.</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className='howdoes ban-cont'>
          <div>
            <h1 className='innerheading text-center'>How does it work?</h1>
            <p className='text-center'>Simple in 3 quick and easy steps.</p>
            <div className='maxing'>
              <div className='row pt-3 pb-3'>
                <div className='col-md-4 d-flex align-items-center'>
                  <div className='stepss'>
                    <p className='mb-0 numberstep'>1</p>
                  </div>
                  <div className='ms-3'>
                    <p className='stepno mb-2'>Step 1</p>
                    <p className='mb-0'>Download the app. Or stay on the website.</p>
                  </div>
                  <div className='ms-3'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='col-md-4 d-flex align-items-center'>
                  <div className='stepss stepss2'>
                    <p className='mb-0 numberstep'>2</p>
                  </div>
                  <div className='ms-3'>
                    <p className='stepno mb-2'>Step 2</p>
                    <p className='mb-0'>Buy Gruncoin with crypto USDT.</p>
                  </div>
                  <div className='ms-3'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='col-md-4 d-flex align-items-center'>
                  <div className='stepss stepss3'>
                    <p className='mb-0 numberstep'>3</p>
                  </div>
                  <div className='ms-3'>
                    <p className='stepno mb-2'>Step 3</p>
                    <p className='mb-0'>Click on the logo of our partners. And let us know what you like to order or reserve via mail or WhatsApp or telegram or call back.</p>
                  </div>
                  <div className='ms-3'>
                    <img className='blackarrowimg' src={blackarrow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}



        <section className='logos_sec partner ban-cont'>
          <h1 className='innerheading text-center'>Our Partners</h1>
          <p className='text-center smallsub mb-4'>Click on the logo of our partners. And let us know what you like to order or reserve via mail or whatsapp or telegram or call back.</p>

          <div className='row' data-aos="zoom-in" data-aos-duration="1500">
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              <a href="https://www.admitad.com/" target='_blank'><img className='w-100' src={Img1} /></a>
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              <a href="https://travelpayouts.com/" target='_blank'><img className='w-100' src={Img2} /></a>
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              <a href="https://www.admitad.com/" target='_blank'><img className='w-100' src={Img1} /></a>
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              <a href="https://travelpayouts.com/" target='_blank'> <img className='w-100' src={Img2} /></a>
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              <a href="https://www.admitad.com/" target='_blank'> <img className='w-100' src={Img1} /></a>
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              <a href="https://travelpayouts.com/" target='_blank'> <img className='w-100' src={Img2} /></a>
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              <a href="https://www.admitad.com/" target='_blank'><img className='w-100' src={Img1} /></a>
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              <a href="https://travelpayouts.com/" target='_blank'><img className='w-100' src={Img2} /></a>
            </div>
          </div>

          <div className='logogrid'>
            {/* <img src={Img1} />
            <img src={Img2} />
            <img src={Img3} />
            <img src={Img4} />
            <img src={Img5} />
            <img src={Img6} />
            <img src={Img7} />
            <img src={Img8} /> */}
            {/* <img src={Img9} />
            <img src={Img10} />
            <img src={Img11} />
            <img src={Img12} />
            <img src={Img13} />
            <img src={Img14} />
            <img src={Img15} />
            <img src={Img16} />
            <img src={Img17} />
            <img src={Img18} />
            <img src={Img19} />
            <img src={Img20} />
            <img src={Img21} /> */}

          </div>
        </section>

        <section className='feature'>
          <div className='text-center'>
            {/* <p className='themeclr'>{featuredata && featuredata[0]?.Title}</p> */}
            <h1 className='innerheading'>Feature of Gruncoin</h1>
            <p className='smallp'>One of the unique features of Gruncoin is its travel services at discount. Users can use Gruncoin to book hotels, air tickets, and other travel-related services, making it an ideal app for frequent travelers. This feature also allows users to take advantage of the bennefits of cryptocurrency, such as low fees and fast transactions when booking travel. </p>

          </div>
          <div className='row mt-5 justify-content-center'>
            <div className='col-md-5' data-aos="zoom-in-down" data-aos-duration="1500">
              <div className='features-list text-center p-4'>
                <img className="mb-3" src={feature} />
                {/* <img src={files} /> */}
                {/* <h4>{featuredesdata && featuredesdata[0]?.Heading}</h4> */}
                <h4 className=' themesub'>About Gruncoin</h4>
                {/* <p>{featuredesdata && featuredesdata[0]?.Description} </p> */}
                <p className='mt-3'>
                  <ReactReadMoreReadLess
                    charLimit={214}
                    readMoreText={"Learn more ▼"}
                    readLessText={"Learn less ▲"}
                  >
                    {myLongText}
                  </ReactReadMoreReadLess>
                </p>
              </div>
            </div>
            <div className='col-md-5' data-aos="zoom-in-down" data-aos-duration="1500">
              <div className='features-list text-center p-4'>
                <img className="mb-3" src={feature} />
                {/* <img src={files} /> */}
                {/* <h4>{featuredesdata && featuredesdata[1]?.Heading}</h4> */}
                <h4 className='  themesub'>Reliabillity garrantee</h4>
                {/* <p>{featuredesdata && featuredesdata[1]?.Description} </p> */}
                <p className='mt-3'>
                  <ReactReadMoreReadLess
                    charLimit={210}
                    readMoreText={"Learn more ▼"}
                    readLessText={"Learn less ▲"}
                  >
                    {myLongText1}
                  </ReactReadMoreReadLess>
                </p>
              </div>
            </div>
            {/* <div className='col-md-4'>
            <div className='features-list text-center p-4'>
                 <img  className="mb-3"src={backurl + `/Images/${featuredesdata && featuredesdata[2]?.logoURI}`} />
                    <h4>{featuredesdata && featuredesdata[2]?.Heading}</h4>
                    <p>{featuredesdata && featuredesdata[2]?.Description} </p>
                 </div>
            </div> */}
          </div>
        </section>
        <section className='banner trading container container_custom'>
          <div className='row align-items-center'>
            <div className='col-md-6' data-aos="fade-up" data-aos-duration="1500">
              <div className='ban-cont'>
                {/* <p className='themeclr'>{aboutdata && aboutdata[2]?.Title}</p> */}
                {/* <h1 className='innerheading'>{aboutdata && aboutdata[2]?.Heading}</h1> */}
                {/* <h1 className='innerheading'>"<span className='subtitles'>99.9% </span>More Energy Efficient"</h1> */}
                <h1>Summary </h1>
                {/* <p className='mt-3'>Gruncoin is a great choise for anyone looking to invest in gold or use cryptocurrency for everyday purchases with 50% discount.</p> */}
                <p className='mt-3'>
                  <ReactReadMoreReadLess
                    charLimit={126}
                    readMoreText={"Learn more ▼"}
                    readLessText={"Learn less ▲"}>
                    {myLongText3}
                  </ReactReadMoreReadLess></p>

                {/* <p>{aboutdata && aboutdata[2]?.Description}</p> */}
                {/* <a target='blank' href="https://labelm.io/launchpad"><button className='btn getstarted'>Get Started</button></a> */}
              </div>
            </div>
            <div className='col-md-6 d-none d-md-block' data-aos="fade-down" data-aos-duration="1500">
              <div className='bannerimg text-center'>
                <img src={sideimg} />
              </div>
            </div>
          </div>
        </section>
        <section className=" themesec roadmap resp991 pt-md-5" id="roadmap">
          <div className="">
            <div className="container container_custom">
              <div className='ban-cont text-center'>
                {/* <p className='themeclr'>{featuredata && featuredata[1]?.Title}</p> */}
                {/* <h1 className='innerheading'>{featuredata && featuredata[1]?.Heading}</h1> */}
                <h1 className='innerheading'>Roadmap of Gruncoin</h1>
                <p className='smallp'>Our mission is to make travel, hotel, electronics, fashion and more 50% more affordable.</p>
                {/* <p className='smallp'>{featuredata && featuredata[1]?.Description}</p> */}
              </div>

              <div className="slider_sec_1 fullinerelative">
                <div className='fullline'></div>
                <OwlCarousel items={3}
                  className="owl-theme"
                  loop={false}
                  nav={false}
                  margin={0} autoplay={false} responsive={responsive1} dots={false}>
                  {roadmapdata && roadmapdata.map((item) =>
                    <div className='car-item completed'>
                      <div>

                        <div className='line'></div>
                        <h6 className=' roadmapbtn'>1 Jun / 2023</h6>
                        <p>Pre order Grun and yield 50%</p>

                      </div>
                    </div>
                  )}

                  {/* <div className='car-item inprogress'>
                           <div>
                                
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Feb-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                
                           </div>
                        </div>  
                        <div className='car-item notyetstart'>
                           <div>
                               
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                
                           </div>
                        </div> 
                        <div className='car-item notyetstart'>
                           <div>
                               
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                
                           </div>
                        </div>
                        <div className='car-item notyetstart'>
                           <div>
                               
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                           </div>
                        </div>
                        <div className='car-item notyetstart'>
                           <div>
                                
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lemuria project expansion with Real Estate, Explorable, Wearable, Tradeable & Learning</p>
                           </div>
                        </div>  */}

                </OwlCarousel>
              </div>

            </div>
          </div>
        </section>


        <section className='faq ban-cont'>
          <div>
            <h1 className='innerheading text-center'>Frequently Asked Questions</h1>
            <p className='text-center smallsub mt-3 mb-3'>keep Grun as investment until you need it or want to cashout.</p>
            <Accordion>
              <div className='row justify-content-center'>
                <div className='col-md-6 mt-4'>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>What does Gruncoin do?</Accordion.Header>
                    <Accordion.Body>
                    We will order for you. You pay us in Grun. At 50% Discount.Or keep keep Grun as investment until you need it or want to cashout. Gruncoin offers 50% discount on goods and services of our partners.
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Must be is Gruncoin backed by gold?</Accordion.Header>
                    <Accordion.Body>
                      Yes.
                      Gruncoin has an unlimited maximum value.
                      And a guaranteed limited minnimum value of 1Grun for 1ozt gold.

                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Can I sell Gruncoin?</Accordion.Header>
                    <Accordion.Body>
                      Yes.
                      Gruncoin will at all time buy back Grun at the current price of gold. 1 Grun for 1 ounce gold. Or morefold.

                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Is Gruncoin safe?</Accordion.Header>
                    <Accordion.Body>
                    Yes. We work with the highest level of security. And no information will be shared with thirth parties.
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Can I unstak?</Accordion.Header>
                    <Accordion.Body>
                    Yes. After the period of staking, you can unlock and unstak and use Gruncoin. 
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6 mt-4'>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>How does it work ? </Accordion.Header>
                    <Accordion.Body>
                      Simple in 3 quick and easy steps.
                      <br></br>  <br></br>
                      Step 1. Download the app. Or stay on the website.
                      <br></br>
                      Step 2. Buy Gruncoin with crypto USDT.
                      <br></br>
                      Step 3. Click on the logo of our partners. And let us know what you like to order or reserve via mail or WhatsApp or telegram or call back.
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Do you have anymore queston?</Accordion.Header>
                    <Accordion.Body> Feel free to mail or app or book a call back.</Accordion.Body>
                  </Accordion.Item>
                </div>
              </div>
            </Accordion>

          </div>
        </section>


        <section className='newsletter ban-cont memberlist'>
          <div className='row align-items-center'>
            <div className='col-md-6 mt-3'>
              <h1 className='letter'>Join News Letter</h1>
              <p className='subletter mb-0'>Lorem ipsum dolor sit amet, consetetur sadipscing</p>
            </div>
            <div className='col-md-6 mt-3'>
              <Form className='d-flex'>
                <Form.Group className="mb-0" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Enter Your Email" />
                </Form.Group>

                <button className='btn btn getstarted ms-3' >Subscribe</button>
              </Form>
            </div>
          </div>
        </section>




      </div>
      <Footer />
    </div>
  )
}

export default Land